import styled from "styled-components";
import Step1 from "../components/Step1";
import Step2 from "../components/Step2";
import Step3 from "../components/Step3";
import StepButton from "../components/StepButton";
import useLeadsForm from "../hooks/useLeadsForm";
import { useContext } from "react";
import { leadFormContext } from "../../context/leadFormContext";
import LeadCreated from "./../components/LeadCreated";
import Step4 from "../components/Step4";
import Step5 from "../components/Step5";
import HeaderForm from "../components/HeaderForm";
import FooterForm from "../components/FooterForm";

export default function LeadsForm() {
  const { step } = useLeadsForm();
  const { completedForm } = useContext(leadFormContext);

  return (
    <>
      <HeaderForm />
      <Container>
        <LeftContainer>
          <AdvisorContent>
            <AvatarAdvisor
              src={`${process.env.REACT_APP_API_URL}/getLeadImage/`}
              alt="Avatar"
            />
            <AdvisorInfo>
              <h3>Hola, somos YPF Solar.</h3>
              <p>Gracias por tu interés en nuestros productos</p>
            </AdvisorInfo>
          </AdvisorContent>
        </LeftContainer>
        {completedForm ? (
          <RightContainer>
            <FormContainer step={1}>
              <LeadCreated />
            </FormContainer>
          </RightContainer>
        ) : (
          <RightContainer>
            <StepsContainer>
              <StepButton step={1} type={step === 1 ? "current" : "check"} />
              <StepButton
                step={2}
                type={step === 2 ? "current" : step > 2 ? "check" : "next"}
              />
              <StepButton
                step={3}
                type={step === 3 ? "current" : step > 3 ? "check" : "next"}
              />
              <StepButton
                step={4}
                type={step === 4 ? "current" : step > 4 ? "check" : "next"}
              />
              <StepButton
                step={5}
                type={step === 5 ? "current" : step > 5 ? "check" : "next"}
              />
            </StepsContainer>
            <FormContainer step={step}>
              <Step1 />
              <Step2 />
              <Step3 />
              <Step4 />
              <Step5 />
            </FormContainer>
          </RightContainer>
        )}
      </Container>
      <FooterForm />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 36vw;
  min-height: 80vh;
  @media (max-width: 768px) {
    display: none;
  }
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f9f9f9;
  overflow-x: hidden;
`;
const AdvisorContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;
const AvatarAdvisor = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;
const AdvisorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
`;
const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  padding-left: 40px;
  gap: 20px;
`;
const FormContainer = styled.div<{ step: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  margin-top: 20px;
  transition: all 0.5s ease;
  transform: translateX(${(props) => (props.step - 1) * -100}%);
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;
