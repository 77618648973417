export default function HeaderForm() {
  return (
    <>
      <header
        className="site-header header-main-layout-1 ast-primary-menu-enabled ast-logo-title-inline ast-hide-custom-menu-mobile ast-builder-menu-toggle-icon ast-mobile-header-inline"
        id="masthead"
        itemType="https://schema.org/WPHeader"
        itemScope={true}
        itemID="#masthead"
      >
        <div id="ast-desktop-header" data-toggle-type="dropdown">
          <div className="ast-desktop-header-content content-align-flex-start ">
            <div
              className="ast-builder-menu-mobile ast-builder-menu ast-builder-menu-mobile-focus-item ast-builder-layout-element site-header-focus-item"
              data-section="section-header-mobile-menu"
            >
              <div className="ast-main-header-bar-alignment">
                <div className="main-header-bar-navigation">
                  <nav
                    className="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item"
                    id="ast-desktop-site-navigation"
                    aria-label="Navegación del sitio"
                    itemType="https://schema.org/SiteNavigationElement"
                    itemScope={true}
                  >
                    <div className="main-navigation">
                      <ul
                        id="ast-desktop-toggle-menu"
                        className="main-header-menu ast-nav-menu ast-flex  submenu-with-border astra-menu-animation-fade  stack-on-mobile"
                        aria-expanded="false"
                      >
                        <li
                          id="menu-item-1180"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1180"
                          aria-haspopup="true"
                        >
                          <a
                            href="https://ypfsolar.com/#"
                            className="menu-link"
                          >
                            Nosotros
                            <span
                              role="presentation"
                              className="dropdown-menu-toggle"
                            >
                              <span className="ast-icon icon-arrow">
                                <svg
                                  className="ast-arrow-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  width="26px"
                                  height="16.043px"
                                  viewBox="57 35.171 26 16.043"
                                  enable-background="new 57 35.171 26 16.043"
                                  xmlSpace="preserve"
                                >
                                  <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                </svg>
                              </span>
                            </span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span className="screen-reader-text">
                              Alternar menú
                            </span>
                            <span className="ast-icon icon-arrow">
                              <svg
                                className="ast-arrow-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                width="26px"
                                height="16.043px"
                                viewBox="57 35.171 26 16.043"
                                enable-background="new 57 35.171 26 16.043"
                                xmlSpace="preserve"
                              >
                                <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                              </svg>
                            </span>
                          </button>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-1264"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1264"
                            >
                              <a
                                href="https://ypfsolar.com/nosotros-2/la-compania/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                La Compañía
                              </a>
                            </li>
                            <li
                              id="menu-item-1261"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1261"
                            >
                              <a
                                href="https://ypfsolar.com/nosotros-2/activos-y-proyectos/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Activos y Proyectos
                              </a>
                            </li>
                            <li
                              id="menu-item-1262"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1262"
                            >
                              <a
                                href="https://ypfsolar.com/nosotros-2/compliance/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Compliance
                              </a>
                            </li>
                            <li
                              id="menu-item-1263"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1263"
                            >
                              <a
                                href="https://ypfsolar.com/nosotros-2/equipo-de-direccion/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Equipo de Dirección
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-30"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-30"
                          aria-haspopup="true"
                        >
                          <a
                            href="https://ypfsolar.com/#"
                            className="menu-link"
                          >
                            Soluciones
                            <span
                              role="presentation"
                              className="dropdown-menu-toggle"
                            >
                              <span className="ast-icon icon-arrow">
                                <svg
                                  className="ast-arrow-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  width="26px"
                                  height="16.043px"
                                  viewBox="57 35.171 26 16.043"
                                  enable-background="new 57 35.171 26 16.043"
                                  xmlSpace="preserve"
                                >
                                  <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                </svg>
                              </span>
                            </span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span className="screen-reader-text">
                              Alternar menú
                            </span>
                            <span className="ast-icon icon-arrow">
                              <svg
                                className="ast-arrow-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                width="26px"
                                height="16.043px"
                                viewBox="57 35.171 26 16.043"
                                enable-background="new 57 35.171 26 16.043"
                                xmlSpace="preserve"
                              >
                                <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                              </svg>
                            </span>
                          </button>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-1181"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1181"
                              aria-haspopup="true"
                            >
                              <a
                                href="https://ypfsolar.com/#"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Para Hogares
                                <span
                                  role="presentation"
                                  className="dropdown-menu-toggle"
                                >
                                  <span className="ast-icon icon-arrow">
                                    <svg
                                      className="ast-arrow-svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      width="26px"
                                      height="16.043px"
                                      viewBox="57 35.171 26 16.043"
                                      enable-background="new 57 35.171 26 16.043"
                                      xmlSpace="preserve"
                                    >
                                      <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span className="screen-reader-text">
                                  Alternar menú
                                </span>
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-1743"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1743"
                                  aria-haspopup="true"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/sistemas-fotovoltaicos/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Sistemas Fotovoltaicos
                                    <span
                                      role="presentation"
                                      className="dropdown-menu-toggle"
                                    >
                                      <span className="ast-icon icon-arrow">
                                        <svg
                                          className="ast-arrow-svg"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="26px"
                                          height="16.043px"
                                          viewBox="57 35.171 26 16.043"
                                          enable-background="new 57 35.171 26 16.043"
                                          xmlSpace="preserve"
                                        >
                                          <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </a>
                                  <button
                                    className="ast-menu-toggle"
                                    aria-expanded="false"
                                  >
                                    <span className="screen-reader-text">
                                      Alternar menú
                                    </span>
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <ul className="sub-menu">
                                    <li
                                      id="menu-item-1550"
                                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1550"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-ongrid/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas OnGrid
                                      </a>
                                    </li>
                                    <li
                                      id="menu-item-1565"
                                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1565"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-offgrid/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas OffGrid
                                      </a>
                                    </li>
                                    <li
                                      id="menu-item-1569"
                                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1569"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/hibridos/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas Híbridos
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li
                                  id="menu-item-313"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-313"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/termotanques/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Termotanques solares
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1482"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1482"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/backup-energetico/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Backup energético
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1834"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1834"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/climatizacion/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Climatización de piscinas
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              id="menu-item-1484"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1484"
                              aria-haspopup="true"
                            >
                              <a
                                href="https://ypfsolar.com/#"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Comercios/Industrias
                                <span
                                  role="presentation"
                                  className="dropdown-menu-toggle"
                                >
                                  <span className="ast-icon icon-arrow">
                                    <svg
                                      className="ast-arrow-svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      width="26px"
                                      height="16.043px"
                                      viewBox="57 35.171 26 16.043"
                                      enable-background="new 57 35.171 26 16.043"
                                      xmlSpace="preserve"
                                    >
                                      <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span className="screen-reader-text">
                                  Alternar menú
                                </span>
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-1742"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1742"
                                  aria-haspopup="true"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/sistemas-fotovoltaicos/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Sistemas Fotovoltaicos
                                    <span
                                      role="presentation"
                                      className="dropdown-menu-toggle"
                                    >
                                      <span className="ast-icon icon-arrow">
                                        <svg
                                          className="ast-arrow-svg"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="26px"
                                          height="16.043px"
                                          viewBox="57 35.171 26 16.043"
                                          enable-background="new 57 35.171 26 16.043"
                                          xmlSpace="preserve"
                                        >
                                          <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </a>
                                  <button
                                    className="ast-menu-toggle"
                                    aria-expanded="false"
                                  >
                                    <span className="screen-reader-text">
                                      Alternar menú
                                    </span>
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <ul className="sub-menu">
                                    <li
                                      id="menu-item-1549"
                                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1549"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-ongrid/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas OnGrid
                                      </a>
                                    </li>
                                    <li
                                      id="menu-item-1564"
                                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1564"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-offgrid/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas OffGrid
                                      </a>
                                    </li>
                                    <li
                                      id="menu-item-1568"
                                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1568"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/hibridos/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Híbridos
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li
                                  id="menu-item-1712"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1712"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/empresas-y-comercios/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Empresas y comercios
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1483"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1483"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/soluciones-para-el-agro/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Soluciones para el agro
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1531"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1531"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/backup-energetico/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Backup energético
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1530"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1530"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/bombeo-solar/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Bombeo Solar
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1713"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1713"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/estaciones-de-servicio/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Estaciones de servicio
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-568"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-568"
                          aria-haspopup="true"
                        >
                          <a
                            href="https://ypfsolar.com/#"
                            className="menu-link"
                          >
                            Distribuidores
                            <span
                              role="presentation"
                              className="dropdown-menu-toggle"
                            >
                              <span className="ast-icon icon-arrow">
                                <svg
                                  className="ast-arrow-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  width="26px"
                                  height="16.043px"
                                  viewBox="57 35.171 26 16.043"
                                  enable-background="new 57 35.171 26 16.043"
                                  xmlSpace="preserve"
                                >
                                  <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                </svg>
                              </span>
                            </span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span className="screen-reader-text">
                              Alternar menú
                            </span>
                            <span className="ast-icon icon-arrow">
                              <svg
                                className="ast-arrow-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                width="26px"
                                height="16.043px"
                                viewBox="57 35.171 26 16.043"
                                enable-background="new 57 35.171 26 16.043"
                                xmlSpace="preserve"
                              >
                                <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                              </svg>
                            </span>
                          </button>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-29"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-29"
                              aria-haspopup="true"
                            >
                              <a
                                href="https://ypfsolar.com/#"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Catálogo de Productos
                                <span
                                  role="presentation"
                                  className="dropdown-menu-toggle"
                                >
                                  <span className="ast-icon icon-arrow">
                                    <svg
                                      className="ast-arrow-svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      width="26px"
                                      height="16.043px"
                                      viewBox="57 35.171 26 16.043"
                                      enable-background="new 57 35.171 26 16.043"
                                      xmlSpace="preserve"
                                    >
                                      <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span className="screen-reader-text">
                                  Alternar menú
                                </span>
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                              </button>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-2361"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2361"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Catálogo de todos los productos – energía
                                    solar
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2193"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2193"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/paneles-solares/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Paneles solares
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1079"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1079"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/estructuras-para-paneles-solares/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Estructuras para paneles solares
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2240"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2240"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/inversores/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Inversores
                                  </a>
                                </li>
                                <li
                                  id="menu-item-3811"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-3811"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/kits-fotovoltaicos/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Kits Fotovoltaicos
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1084"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1084"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/baterias-para-energia-solar/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Baterías
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2329"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2329"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/backup-energetico/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Backup Energético
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2328"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2328"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/materiales-instalaciones-fotovoltaicas/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Materiales para instalaciones fotovoltaicas
                                  </a>
                                </li>
                                <li
                                  id="menu-item-3791"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-3791"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/movilidad/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Movilidad Eléctrica y accesorios
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1080"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1080"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/termotanques-solares/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Termotanques solares
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1081"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1081"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/bombeo-solar/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Bombeo Solar
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2197"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2197"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/climatizacion_de_piscinas/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Climatización de Piscinas
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              id="menu-item-4370"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4370"
                            >
                              <a
                                href="https://sites.google.com/sustentator.com/academia-ypf-solar/portal-capacitaciones"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Academia YPF Solar
                              </a>
                            </li>
                            <li
                              id="menu-item-1711"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1711"
                            >
                              <a
                                href="https://ypfsolar.com/quiero-ser-distribuidor/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Quiero ser Distribuidor
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-610"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-610"
                        >
                          <a
                            href="https://ypfsolar.com/noticias/"
                            className="menu-link"
                          >
                            Blog
                          </a>
                        </li>
                        <li
                          id="menu-item-4363"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-4363"
                          aria-haspopup="true"
                        >
                          <a
                            href="https://ypfsolar.com/ventas/"
                            className="menu-link"
                          >
                            Contacto
                            <span
                              role="presentation"
                              className="dropdown-menu-toggle"
                            >
                              <span className="ast-icon icon-arrow">
                                <svg
                                  className="ast-arrow-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  width="26px"
                                  height="16.043px"
                                  viewBox="57 35.171 26 16.043"
                                  enable-background="new 57 35.171 26 16.043"
                                  xmlSpace="preserve"
                                >
                                  <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                </svg>
                              </span>
                            </span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span className="screen-reader-text">
                              Alternar menú
                            </span>
                            <span className="ast-icon icon-arrow">
                              <svg
                                className="ast-arrow-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                width="26px"
                                height="16.043px"
                                viewBox="57 35.171 26 16.043"
                                enable-background="new 57 35.171 26 16.043"
                                xmlSpace="preserve"
                              >
                                <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                              </svg>
                            </span>
                          </button>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-4132"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4132"
                            >
                              <a
                                href="https://ypfsolar.com/ventas/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Ventas
                              </a>
                            </li>
                            <li
                              id="menu-item-3184"
                              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3184"
                            >
                              <a
                                href="https://ypfsolar.com/soporte-tecnico/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Soporte Técnico
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        <div
          id="ast-mobile-header"
          className="ast-mobile-header-wrap "
          data-type="dropdown"
        >
          <div className="ast-mobile-header-content content-align-flex-start ">
            <div
              className="ast-builder-menu-mobile ast-builder-menu ast-builder-menu-mobile-focus-item ast-builder-layout-element site-header-focus-item"
              data-section="section-header-mobile-menu"
            >
              <div className="ast-main-header-bar-alignment">
                <div className="main-header-bar-navigation">
                  <nav
                    className="site-navigation ast-flex-grow-1 navigation-accessibility site-header-focus-item"
                    id="ast-mobile-site-navigation"
                    aria-label="Navegación del sitio"
                    itemType="https://schema.org/SiteNavigationElement"
                    itemScope={true}
                  >
                    <div className="main-navigation">
                      <ul
                        id="ast-hf-mobile-menu"
                        className="main-header-menu ast-nav-menu ast-flex  submenu-with-border astra-menu-animation-fade  stack-on-mobile"
                      >
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1180">
                          <a
                            href="https://ypfsolar.com/#"
                            className="menu-link"
                          >
                            Nosotros
                            <span
                              role="presentation"
                              className="dropdown-menu-toggle"
                            >
                              <span className="ast-icon icon-arrow">
                                <svg
                                  className="ast-arrow-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  width="26px"
                                  height="16.043px"
                                  viewBox="57 35.171 26 16.043"
                                  enable-background="new 57 35.171 26 16.043"
                                  xmlSpace="preserve"
                                >
                                  <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                </svg>
                              </span>
                            </span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span className="screen-reader-text">
                              Alternar menú
                            </span>
                            <span className="ast-icon icon-arrow">
                              <svg
                                className="ast-arrow-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                width="26px"
                                height="16.043px"
                                viewBox="57 35.171 26 16.043"
                                enable-background="new 57 35.171 26 16.043"
                                xmlSpace="preserve"
                              >
                                <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                              </svg>
                            </span>
                          </button>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1264">
                              <a
                                href="https://ypfsolar.com/nosotros-2/la-compania/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                La Compañía
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1261">
                              <a
                                href="https://ypfsolar.com/nosotros-2/activos-y-proyectos/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Activos y Proyectos
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1262">
                              <a
                                href="https://ypfsolar.com/nosotros-2/compliance/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Compliance
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1263">
                              <a
                                href="https://ypfsolar.com/nosotros-2/equipo-de-direccion/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Equipo de Dirección
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-30">
                          <a
                            href="https://ypfsolar.com/#"
                            className="menu-link"
                          >
                            Soluciones
                            <span
                              role="presentation"
                              className="dropdown-menu-toggle"
                            >
                              <span className="ast-icon icon-arrow">
                                <svg
                                  className="ast-arrow-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  width="26px"
                                  height="16.043px"
                                  viewBox="57 35.171 26 16.043"
                                  enable-background="new 57 35.171 26 16.043"
                                  xmlSpace="preserve"
                                >
                                  <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                </svg>
                              </span>
                            </span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span className="screen-reader-text">
                              Alternar menú
                            </span>
                            <span className="ast-icon icon-arrow">
                              <svg
                                className="ast-arrow-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                width="26px"
                                height="16.043px"
                                viewBox="57 35.171 26 16.043"
                                enable-background="new 57 35.171 26 16.043"
                                xmlSpace="preserve"
                              >
                                <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                              </svg>
                            </span>
                          </button>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1181">
                              <a
                                href="https://ypfsolar.com/#"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Para Hogares
                                <span
                                  role="presentation"
                                  className="dropdown-menu-toggle"
                                >
                                  <span className="ast-icon icon-arrow">
                                    <svg
                                      className="ast-arrow-svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      width="26px"
                                      height="16.043px"
                                      viewBox="57 35.171 26 16.043"
                                      enable-background="new 57 35.171 26 16.043"
                                      xmlSpace="preserve"
                                    >
                                      <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span className="screen-reader-text">
                                  Alternar menú
                                </span>
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                              </button>
                              <ul className="sub-menu">
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1743">
                                  <a
                                    href="https://ypfsolar.com/soluciones/sistemas-fotovoltaicos/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Sistemas Fotovoltaicos
                                    <span
                                      role="presentation"
                                      className="dropdown-menu-toggle"
                                    >
                                      <span className="ast-icon icon-arrow">
                                        <svg
                                          className="ast-arrow-svg"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="26px"
                                          height="16.043px"
                                          viewBox="57 35.171 26 16.043"
                                          enable-background="new 57 35.171 26 16.043"
                                          xmlSpace="preserve"
                                        >
                                          <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </a>
                                  <button
                                    className="ast-menu-toggle"
                                    aria-expanded="false"
                                  >
                                    <span className="screen-reader-text">
                                      Alternar menú
                                    </span>
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <ul className="sub-menu">
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1550">
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-ongrid/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas OnGrid
                                      </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1565">
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-offgrid/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas OffGrid
                                      </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1569">
                                      <a
                                        href="https://ypfsolar.com/soluciones/hibridos/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas Híbridos
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-313">
                                  <a
                                    href="https://ypfsolar.com/soluciones/termotanques/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Termotanques solares
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1482">
                                  <a
                                    href="https://ypfsolar.com/soluciones/backup-energetico/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Backup energético
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1834">
                                  <a
                                    href="https://ypfsolar.com/soluciones/climatizacion/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Climatización de piscinas
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1484">
                              <a
                                href="https://ypfsolar.com/#"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Comercios/Industrias
                                <span
                                  role="presentation"
                                  className="dropdown-menu-toggle"
                                >
                                  <span className="ast-icon icon-arrow">
                                    <svg
                                      className="ast-arrow-svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      width="26px"
                                      height="16.043px"
                                      viewBox="57 35.171 26 16.043"
                                      enable-background="new 57 35.171 26 16.043"
                                      xmlSpace="preserve"
                                    >
                                      <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span className="screen-reader-text">
                                  Alternar menú
                                </span>
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                              </button>
                              <ul className="sub-menu">
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-1742">
                                  <a
                                    href="https://ypfsolar.com/soluciones/sistemas-fotovoltaicos/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Sistemas Fotovoltaicos
                                    <span
                                      role="presentation"
                                      className="dropdown-menu-toggle"
                                    >
                                      <span className="ast-icon icon-arrow">
                                        <svg
                                          className="ast-arrow-svg"
                                          xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink"
                                          version="1.1"
                                          x="0px"
                                          y="0px"
                                          width="26px"
                                          height="16.043px"
                                          viewBox="57 35.171 26 16.043"
                                          enable-background="new 57 35.171 26 16.043"
                                          xmlSpace="preserve"
                                        >
                                          <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </a>
                                  <button
                                    className="ast-menu-toggle"
                                    aria-expanded="false"
                                  >
                                    <span className="screen-reader-text">
                                      Alternar menú
                                    </span>
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                  </button>
                                  <ul className="sub-menu">
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1549">
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-ongrid/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas OnGrid
                                      </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1564">
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-offgrid/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Sistemas OffGrid
                                      </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1568">
                                      <a
                                        href="https://ypfsolar.com/soluciones/hibridos/"
                                        className="menu-link"
                                      >
                                        <span className="ast-icon icon-arrow">
                                          <svg
                                            className="ast-arrow-svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            width="26px"
                                            height="16.043px"
                                            viewBox="57 35.171 26 16.043"
                                            enable-background="new 57 35.171 26 16.043"
                                            xmlSpace="preserve"
                                          >
                                            <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                          </svg>
                                        </span>
                                        Híbridos
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1712">
                                  <a
                                    href="https://ypfsolar.com/soluciones/empresas-y-comercios/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Empresas y comercios
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1483">
                                  <a
                                    href="https://ypfsolar.com/soluciones/soluciones-para-el-agro/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Soluciones para el agro
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1531">
                                  <a
                                    href="https://ypfsolar.com/soluciones/backup-energetico/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Backup energético
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1530">
                                  <a
                                    href="https://ypfsolar.com/soluciones/bombeo-solar/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Bombeo Solar
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1713">
                                  <a
                                    href="https://ypfsolar.com/soluciones/estaciones-de-servicio/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Estaciones de servicio
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-568">
                          <a
                            href="https://ypfsolar.com/#"
                            className="menu-link"
                          >
                            Distribuidores
                            <span
                              role="presentation"
                              className="dropdown-menu-toggle"
                            >
                              <span className="ast-icon icon-arrow">
                                <svg
                                  className="ast-arrow-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  width="26px"
                                  height="16.043px"
                                  viewBox="57 35.171 26 16.043"
                                  enable-background="new 57 35.171 26 16.043"
                                  xmlSpace="preserve"
                                >
                                  <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                </svg>
                              </span>
                            </span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span className="screen-reader-text">
                              Alternar menú
                            </span>
                            <span className="ast-icon icon-arrow">
                              <svg
                                className="ast-arrow-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                width="26px"
                                height="16.043px"
                                viewBox="57 35.171 26 16.043"
                                enable-background="new 57 35.171 26 16.043"
                                xmlSpace="preserve"
                              >
                                <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                              </svg>
                            </span>
                          </button>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-29">
                              <a
                                href="https://ypfsolar.com/#"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Catálogo de Productos
                                <span
                                  role="presentation"
                                  className="dropdown-menu-toggle"
                                >
                                  <span className="ast-icon icon-arrow">
                                    <svg
                                      className="ast-arrow-svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      version="1.1"
                                      x="0px"
                                      y="0px"
                                      width="26px"
                                      height="16.043px"
                                      viewBox="57 35.171 26 16.043"
                                      enable-background="new 57 35.171 26 16.043"
                                      xmlSpace="preserve"
                                    >
                                      <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </a>
                              <button
                                className="ast-menu-toggle"
                                aria-expanded="false"
                              >
                                <span className="screen-reader-text">
                                  Alternar menú
                                </span>
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                              </button>
                              <ul className="sub-menu">
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2361">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Catálogo de todos los productos – energía
                                    solar
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2193">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/paneles-solares/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Paneles solares
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1079">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/estructuras-para-paneles-solares/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Estructuras para paneles solares
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2240">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/inversores/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Inversores
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-3811">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/kits-fotovoltaicos/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Kits Fotovoltaicos
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1084">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/baterias-para-energia-solar/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Baterías
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2329">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/backup-energetico/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Backup Energético
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2328">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/materiales-instalaciones-fotovoltaicas/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Materiales para instalaciones fotovoltaicas
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-3791">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/movilidad/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Movilidad Eléctrica y accesorios
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1080">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/termotanques-solares/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Termotanques solares
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-1081">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/bombeo-solar/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Bombeo Solar
                                  </a>
                                </li>
                                <li className="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-2197">
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/climatizacion_de_piscinas/"
                                    className="menu-link"
                                  >
                                    <span className="ast-icon icon-arrow">
                                      <svg
                                        className="ast-arrow-svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        width="26px"
                                        height="16.043px"
                                        viewBox="57 35.171 26 16.043"
                                        enable-background="new 57 35.171 26 16.043"
                                        xmlSpace="preserve"
                                      >
                                        <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                      </svg>
                                    </span>
                                    Climatización de Piscinas
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4370">
                              <a
                                href="https://sites.google.com/sustentator.com/academia-ypf-solar/portal-capacitaciones"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Academia YPF Solar
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1711">
                              <a
                                href="https://ypfsolar.com/quiero-ser-distribuidor/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Quiero ser Distribuidor
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-610">
                          <a
                            href="https://ypfsolar.com/noticias/"
                            className="menu-link"
                          >
                            Blog
                          </a>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-4363">
                          <a
                            href="https://ypfsolar.com/ventas/"
                            className="menu-link"
                          >
                            Contacto
                            <span
                              role="presentation"
                              className="dropdown-menu-toggle"
                            >
                              <span className="ast-icon icon-arrow">
                                <svg
                                  className="ast-arrow-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  width="26px"
                                  height="16.043px"
                                  viewBox="57 35.171 26 16.043"
                                  enable-background="new 57 35.171 26 16.043"
                                  xmlSpace="preserve"
                                >
                                  <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                </svg>
                              </span>
                            </span>
                          </a>
                          <button
                            className="ast-menu-toggle"
                            aria-expanded="false"
                          >
                            <span className="screen-reader-text">
                              Alternar menú
                            </span>
                            <span className="ast-icon icon-arrow">
                              <svg
                                className="ast-arrow-svg"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                width="26px"
                                height="16.043px"
                                viewBox="57 35.171 26 16.043"
                                enable-background="new 57 35.171 26 16.043"
                                xmlSpace="preserve"
                              >
                                <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                              </svg>
                            </span>
                          </button>
                          <ul className="sub-menu">
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4132">
                              <a
                                href="https://ypfsolar.com/ventas/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Ventas
                              </a>
                            </li>
                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3184">
                              <a
                                href="https://ypfsolar.com/soporte-tecnico/"
                                className="menu-link"
                              >
                                <span className="ast-icon icon-arrow">
                                  <svg
                                    className="ast-arrow-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="26px"
                                    height="16.043px"
                                    viewBox="57 35.171 26 16.043"
                                    enable-background="new 57 35.171 26 16.043"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
                                  </svg>
                                </span>
                                Soporte Técnico
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </header>

      <div
        data-elementor-type="product-archive"
        data-elementor-id="10"
        className="elementor elementor-10 elementor-location-archive product"
      >
        <div className="elementor-container elementor-column-gap-default" style={{
          display: "flex",
          paddingLeft: "100px",
        }}>
          <div
            className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-12ecc61"
            data-id="12ecc61"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-e0a1028 elementor-widget elementor-widget-image"
                data-id="e0a1028"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <a href="https://www.ypfsolar.com/">
                    <img
                      width="250"
                      height="45"
                      src="/logo-ypf-solar-horizontal.gif"
                      className="attachment-large size-large wp-image-2483"
                      alt=""
                    />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-168b07c"
            data-id="168b07c"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-0c61e5f uael-nav-menu__align-left uael-submenu-open-hover uael-submenu-icon-arrow uael-submenu-animation-none uael-link-redirect-child uael-nav-menu__breakpoint-tablet uael-nav-menu-toggle-label-no elementor-widget elementor-widget-uael-nav-menu"
                data-id="0c61e5f"
                data-element_type="widget"
                data-widget_type="uael-nav-menu.default"
              >
                <div className="elementor-widget-container">
                  <div
                    className="uael-nav-menu uael-layout-horizontal uael-nav-menu-layout uael-pointer__none"
                    data-layout="horizontal"
                  >
                    <div
                      className="uael-nav-menu__toggle elementor-clickable"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="uael-nav-menu-icon">
                        <i
                          aria-hidden="true"
                          tabIndex={0}
                          className="fas fa-align-justify"
                        ></i>{" "}
                      </div>
                    </div>
                    <nav
                      className="uael-nav-menu__layout-horizontal uael-nav-menu__submenu-arrow"
                      data-toggle-icon='&lt;i aria-hidden="true" tabindex="0" className="fas fa-align-justify"&gt;&lt;/i&gt;'
                      data-close-icon='&lt;i aria-hidden="true" tabindex="0" className="far fa-window-close"&gt;&lt;/i&gt;'
                      data-full-width="yes"
                    >
                      <ul id="menu-1-0c61e5f" className="uael-nav-menu">
                        <li
                          id="menu-item-1180"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children parent uael-has-submenu uael-creative-menu parent-has-child"
                        >
                          <div className="uael-has-submenu-container">
                            <a
                              href="https://ypfsolar.com/#"
                              className="uael-menu-item"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Nosotros
                              <span className="uael-menu-toggle sub-arrow uael-menu-child-0">
                                <i className="fa"></i>
                              </span>
                            </a>
                          </div>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-1264"
                              className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                            >
                              <a
                                href="https://ypfsolar.com/nosotros-2/la-compania/"
                                className="uael-sub-menu-item"
                                style={{ paddingLeft: "35px" }}
                              >
                                La Compañía
                              </a>
                            </li>
                            <li
                              id="menu-item-1261"
                              className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                            >
                              <a
                                href="https://ypfsolar.com/nosotros-2/activos-y-proyectos/"
                                className="uael-sub-menu-item"
                                style={{ paddingLeft: "35px" }}
                              >
                                Activos y Proyectos
                              </a>
                            </li>
                            <li
                              id="menu-item-1262"
                              className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                            >
                              <a
                                href="https://ypfsolar.com/nosotros-2/compliance/"
                                className="uael-sub-menu-item"
                                style={{ paddingLeft: "35px" }}
                              >
                                Compliance
                              </a>
                            </li>
                            <li
                              id="menu-item-1263"
                              className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                            >
                              <a
                                href="https://ypfsolar.com/nosotros-2/equipo-de-direccion/"
                                className="uael-sub-menu-item"
                                style={{ paddingLeft: "35px" }}
                              >
                                Equipo de Dirección
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-30"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children parent uael-has-submenu uael-creative-menu parent-has-child"
                        >
                          <div className="uael-has-submenu-container">
                            <a
                              href="https://ypfsolar.com/#"
                              className="uael-menu-item"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Soluciones
                              <span className="uael-menu-toggle sub-arrow uael-menu-child-0">
                                <i className="fa"></i>
                              </span>
                            </a>
                          </div>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-1181"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children uael-has-submenu uael-creative-menu parent-has-child"
                            >
                              <div className="uael-has-submenu-container">
                                <a
                                  href="https://ypfsolar.com/#"
                                  className="uael-sub-menu-item"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  style={{ paddingLeft: "35px" }}
                                >
                                  Para Hogares
                                  <span className="uael-menu-toggle sub-arrow uael-menu-child-1">
                                    <i className="fa"></i>
                                  </span>
                                </a>
                              </div>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-1743"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children uael-has-submenu uael-creative-menu parent-has-child"
                                >
                                  <div className="uael-has-submenu-container">
                                    <a
                                      href="https://ypfsolar.com/soluciones/sistemas-fotovoltaicos/"
                                      className="uael-sub-menu-item"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      style={{ paddingLeft: "35px" }}
                                    >
                                      Sistemas Fotovoltaicos
                                      <span className="uael-menu-toggle sub-arrow uael-menu-child-2">
                                        <i className="fa"></i>
                                      </span>
                                    </a>
                                  </div>
                                  <ul className="sub-menu">
                                    <li
                                      id="menu-item-1550"
                                      className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-ongrid/"
                                        className="uael-sub-menu-item"
                                        style={{ paddingLeft: "35px" }}
                                      >
                                        Sistemas OnGrid
                                      </a>
                                    </li>
                                    <li
                                      id="menu-item-1565"
                                      className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-offgrid/"
                                        className="uael-sub-menu-item"
                                        style={{ paddingLeft: "35px" }}
                                      >
                                        Sistemas OffGrid
                                      </a>
                                    </li>
                                    <li
                                      id="menu-item-1569"
                                      className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/hibridos/"
                                        className="uael-sub-menu-item"
                                        style={{ paddingLeft: "35px" }}
                                      >
                                        Sistemas Híbridos
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li
                                  id="menu-item-313"
                                  className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/termotanques/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Termotanques solares
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1482"
                                  className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/backup-energetico/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Backup energético
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1834"
                                  className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/climatizacion/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Climatización de piscinas
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              id="menu-item-1484"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children uael-has-submenu uael-creative-menu parent-has-child"
                            >
                              <div className="uael-has-submenu-container">
                                <a
                                  href="https://ypfsolar.com/#"
                                  className="uael-sub-menu-item"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  style={{ paddingLeft: "35px" }}
                                >
                                  Comercios/Industrias
                                  <span className="uael-menu-toggle sub-arrow uael-menu-child-1">
                                    <i className="fa"></i>
                                  </span>
                                </a>
                              </div>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-1742"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children uael-has-submenu uael-creative-menu parent-has-child"
                                >
                                  <div className="uael-has-submenu-container">
                                    <a
                                      href="https://ypfsolar.com/soluciones/sistemas-fotovoltaicos/"
                                      className="uael-sub-menu-item"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      style={{ paddingLeft: "35px" }}
                                    >
                                      Sistemas Fotovoltaicos
                                      <span className="uael-menu-toggle sub-arrow uael-menu-child-2">
                                        <i className="fa"></i>
                                      </span>
                                    </a>
                                  </div>
                                  <ul className="sub-menu">
                                    <li
                                      id="menu-item-1549"
                                      className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-ongrid/"
                                        className="uael-sub-menu-item"
                                        style={{ paddingLeft: "35px" }}
                                      >
                                        Sistemas OnGrid
                                      </a>
                                    </li>
                                    <li
                                      id="menu-item-1564"
                                      className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/sistemas-offgrid/"
                                        className="uael-sub-menu-item"
                                        style={{ paddingLeft: "35px" }}
                                      >
                                        Sistemas OffGrid
                                      </a>
                                    </li>
                                    <li
                                      id="menu-item-1568"
                                      className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                    >
                                      <a
                                        href="https://ypfsolar.com/soluciones/hibridos/"
                                        className="uael-sub-menu-item"
                                        style={{ paddingLeft: "35px" }}
                                      >
                                        Híbridos
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li
                                  id="menu-item-1712"
                                  className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/empresas-y-comercios/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Empresas y comercios
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1483"
                                  className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/soluciones-para-el-agro/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Soluciones para el agro
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1531"
                                  className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/backup-energetico/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Backup energético
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1530"
                                  className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/bombeo-solar/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Bombeo Solar
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1713"
                                  className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/soluciones/estaciones-de-servicio/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Estaciones de servicio
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-568"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children parent uael-has-submenu uael-creative-menu parent-has-child"
                        >
                          <div className="uael-has-submenu-container">
                            <a
                              href="https://ypfsolar.com/#"
                              className="uael-menu-item"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Distribuidores
                              <span className="uael-menu-toggle sub-arrow uael-menu-child-0">
                                <i className="fa"></i>
                              </span>
                            </a>
                          </div>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-29"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children uael-has-submenu uael-creative-menu parent-has-child"
                            >
                              <div className="uael-has-submenu-container">
                                <a
                                  href="https://ypfsolar.com/#"
                                  className="uael-sub-menu-item"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  style={{ paddingLeft: "35px" }}
                                >
                                  Catálogo de Productos
                                  <span className="uael-menu-toggle sub-arrow uael-menu-child-1">
                                    <i className="fa"></i>
                                  </span>
                                </a>
                              </div>
                              <ul className="sub-menu">
                                <li
                                  id="menu-item-2361"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Catálogo de todos los productos – energía
                                    solar
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2193"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/paneles-solares/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Paneles solares
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1079"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/estructuras-para-paneles-solares/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Estructuras para paneles solares
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2240"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/inversores/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Inversores
                                  </a>
                                </li>
                                <li
                                  id="menu-item-3811"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/kits-fotovoltaicos/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Kits Fotovoltaicos
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1084"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/baterias-para-energia-solar/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Baterías
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2329"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/backup-energetico/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Backup Energético
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2328"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/materiales-instalaciones-fotovoltaicas/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Materiales para instalaciones fotovoltaicas
                                  </a>
                                </li>
                                <li
                                  id="menu-item-3791"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/movilidad/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Movilidad Eléctrica y accesorios
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1080"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/termotanques-solares/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Termotanques solares
                                  </a>
                                </li>
                                <li
                                  id="menu-item-1081"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/bombeo-solar/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Bombeo Solar
                                  </a>
                                </li>
                                <li
                                  id="menu-item-2197"
                                  className="menu-item menu-item-type-taxonomy menu-item-object-product_cat uael-creative-menu"
                                >
                                  <a
                                    href="https://ypfsolar.com/categoria-producto/todos-los-productos/climatizacion_de_piscinas/"
                                    className="uael-sub-menu-item"
                                    style={{ paddingLeft: "35px" }}
                                  >
                                    Climatización de Piscinas
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              id="menu-item-4370"
                              className="menu-item menu-item-type-custom menu-item-object-custom uael-creative-menu"
                            >
                              <a
                                href="https://sites.google.com/sustentator.com/academia-ypf-solar/portal-capacitaciones"
                                className="uael-sub-menu-item"
                                style={{ paddingLeft: "35px" }}
                              >
                                Academia YPF Solar
                              </a>
                            </li>
                            <li
                              id="menu-item-1711"
                              className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                            >
                              <a
                                href="https://ypfsolar.com/quiero-ser-distribuidor/"
                                className="uael-sub-menu-item"
                                style={{ paddingLeft: "35px" }}
                              >
                                Quiero ser Distribuidor
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          id="menu-item-610"
                          className="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"
                        >
                          <a
                            href="https://ypfsolar.com/noticias/"
                            className="uael-menu-item"
                          >
                            Blog
                          </a>
                        </li>
                        <li
                          id="menu-item-4363"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children parent uael-has-submenu uael-creative-menu parent-has-child"
                        >
                          <div className="uael-has-submenu-container">
                            <a
                              href="https://ypfsolar.com/ventas/"
                              className="uael-menu-item"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Contacto
                              <span className="uael-menu-toggle sub-arrow uael-menu-child-0">
                                <i className="fa"></i>
                              </span>
                            </a>
                          </div>
                          <ul className="sub-menu">
                            <li
                              id="menu-item-4132"
                              className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                            >
                              <a
                                href="https://ypfsolar.com/ventas/"
                                className="uael-sub-menu-item"
                                style={{ paddingLeft: "35px" }}
                              >
                                Ventas
                              </a>
                            </li>
                            <li
                              id="menu-item-3184"
                              className="menu-item menu-item-type-post_type menu-item-object-page uael-creative-menu"
                            >
                              <a
                                href="https://ypfsolar.com/soporte-tecnico/"
                                className="uael-sub-menu-item"
                                style={{ paddingLeft: "35px" }}
                              >
                                Soporte Técnico
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="entry-header ast-header-without-markup"></header>
    </>
  );
}
