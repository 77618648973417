import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import firebaseConfig from "./firebase/firebase";
import { NotificationProvider } from "./context/notificationsContext";
import { UserProvider } from "./context/userContext";
import Notification from "./commonComponents/Notification";
import { DocumentNotificationProvider } from "./context/documentsNotContext";
import DocumentNotification from "./commonComponents/DocumentsNotification";
import LeadsForm from "./leads/screens/leadsForm";
import { LeadFormProvider } from "./context/leadFormContext";
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export default function App() {
  return (
    <UserProvider>
      <DocumentNotificationProvider>
        <NotificationProvider>
          <Notification />
          <DocumentNotification />
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <LeadFormProvider>
                    <LeadsForm />
                  </LeadFormProvider>
                }
              />
              <Route path="*" element={<h2>404</h2>} />
            </Routes>
          </BrowserRouter>
        </NotificationProvider>
      </DocumentNotificationProvider>
    </UserProvider>
  );
}
