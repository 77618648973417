import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default function FooterForm() {
  return (
    <>
      <div
        data-elementor-type="footer"
        data-elementor-id="44"
        className="elementor elementor-44 elementor-location-footer"
        style={{ backgroundColor: "#128297" }}
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-0bdb994 elementor-section-full_width elementor-section-height-min-height elementor-section-items-top elementor-section-height-default"
          data-id="0bdb994"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a2c2e30"
              data-id="a2c2e30"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-e954920 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="e954920"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f5ae931"
                      data-id="f5ae931"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-0c1fd40 elementor-widget elementor-widget-image"
                          data-id="0c1fd40"
                          data-element_type="widget"
                          data-widget_type="image.default"
                        >
                          <div className="elementor-widget-container">
                            <img
                              width="150"
                              height="58"
                              src="https://ypfsolar.com/wp-content/uploads/2022/04/Logo-blanco-ypf-solar.png"
                              className="attachment-large size-large wp-image-108"
                              alt=""
                              loading="lazy"
                            />{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-de5a0af elementor-widget elementor-widget-text-editor"
                          data-id="de5a0af"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <p>
                              © YPF Solar
                              <br />
                              Tapalqué 6260, CP 1440.
                              <br />
                              Ciudad Autónoma de Buenos Aires.
                              <br />
                              Buenos Aires, Argentina.
                              <br />
                              Tel: +54-11-2568-5853
                              <br />
                              Mail:
                              <span style={{ color: "#FFFFFF" }}>
                                {" "}
                                <a
                                  style={{ color: "#FFFFFF" }}
                                  href="mailto:hola@ypfsolar.com"
                                >
                                  hola@ypfsolar.com
                                </a>
                              </span>
                            </p>{" "}
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-63877a4 elementor-widget elementor-widget-text-editor"
                          data-id="63877a4"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <span style={{ color: "#FFFFFF" }}>
                              <a
                                style={{ color: "#FFFFFF" }}
                                href="https://docs.google.com/forms/d/e/1FAIpQLSezdPQT9C5TDi5sWlqXjDS4lROv8jZ2GgxdHcahLRvrDBDYQg/viewform"
                                target="_blank"
                                rel="noopener"
                              >
                                RRHH
                              </a>
                            </span>
                            <span style={{ color: "#FFFFFF" }}>
                              <a
                                style={{ color: "#FFFFFF" }}
                                href="https://ypfsolar.com/soporte-tecnico/"
                              >
                                {" "}
                                | SOPORTE TÉCNICO
                              </a>
                            </span>
                            <span style={{ color: "#FFFFFF" }}>
                              <a
                                style={{ color: "#FFFFFF" }}
                                href="https://ypfsolar.com/sitemap/"
                              >
                                {" "}
                                | MAPA DEL SITIO
                              </a>
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e31377a"
                      data-id="e31377a"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-f447769 elementor-grid-4 e-grid-align-right elementor-shape-rounded elementor-widget elementor-widget-social-icons"
                          data-id="f447769"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div className="elementor-widget-container">
                            {" "}
                            <div
                              className="elementor-social-icons-wrapper elementor-grid"
                              style={{
                                display: "flex",
                                width: "100%",
                                gap: "20px",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                height: "50px",
                              }}
                            >
                              <span className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-2e8cdd9"
                                  href="https://www.facebook.com/ypfsolaroficial"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Facebook
                                  </span>
                                  <FontAwesomeIcon
                                    color="#FFF"
                                    style={{
                                      fontSize: "25px",
                                    }}
                                    icon={faFacebook}
                                  />{" "}
                                </a>
                              </span>
                              <span className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-1bc3ef5"
                                  href="https://www.instagram.com/ypfsolar.oficial/"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Instagram
                                  </span>
                                  <FontAwesomeIcon
                                    color="#FFF"
                                    style={{
                                      fontSize: "25px",
                                    }}
                                    icon={faInstagram}
                                  />{" "}
                                </a>
                              </span>
                              <span className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-repeater-item-22a4e06"
                                  href="https://www.youtube.com/c/YPFSolar"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Youtube
                                  </span>
                                  <FontAwesomeIcon
                                    color="#FFF"
                                    style={{
                                      fontSize: "25px",
                                    }}
                                    icon={faYoutube}
                                  />{" "}
                                </a>
                              </span>
                              <span className="elementor-grid-item">
                                <a
                                  className="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-e040db9"
                                  href="https://www.linkedin.com/company/ypfsolar/"
                                  target="_blank"
                                >
                                  <span className="elementor-screen-only">
                                    Linkedin
                                  </span>
                                  <FontAwesomeIcon
                                    color="#FFF"
                                    style={{
                                      fontSize: "25px",
                                    }}
                                    icon={faLinkedin}
                                  />{" "}
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
